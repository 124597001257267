import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';
import { VideoData } from '../ViralActionPage';
import { useUser } from '../contexts/UserContext';
import { useLogin } from '../contexts/LoginContext';
import LoginModal from './LoginModal';
import ButtonGroup from '@mui/material/ButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';

const StyledBox = styled('div')(({ theme }) => ({
  alignSelf: 'center',
  width: '100%',
  height: 400,
  marginTop: theme.spacing(8),
  borderRadius: theme.shape.borderRadius,
  outline: '6px solid',
  outlineColor: 'hsla(220, 25%, 80%, 0.2)',
  border: '1px solid',
  borderColor: theme.palette.grey[200],
  boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
  backgroundImage: `url(${process.env.PUBLIC_URL}/landing.png)`,
  backgroundSize: 'cover',
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(10),
    height: 700,
  },
  ...theme.applyStyles('dark', {
    boxShadow: '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
    backgroundImage: `url(${process.env.PUBLIC_URL}/landing.png)`,
    outlineColor: 'hsla(220, 20%, 42%, 0.1)',
    borderColor: theme.palette.grey[700],
  }),
}));

interface HeroProps {
  error?: boolean;
  landing?: boolean;
  message?: string;
}

export default function Hero({ error = false, landing = true, message = "" }: HeroProps) {
  const navigate = useNavigate();
  const [youtubeLink, setYoutubeLink] = useState('');
  const [clipCount, setClipCount] = useState(4);
  const { user } = useUser();
  const { openLoginModal } = useLogin();
  const [userPlan, setUserPlan] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchUserPlan() {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/get_current_plan`, {
          credentials: 'include',
        });
        if (response.ok) {
          const data = await response.json();
          setUserPlan(data.plan);
        }
      } catch (error) {
        console.error('Error fetching user plan:', error);
      }
    }

    if (user) {
      fetchUserPlan();
    }
  }, [user]);

  async function handleGenerate(event: React.MouseEvent<HTMLButtonElement>): Promise<void> {
    if (!user) {
      openLoginModal();
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/getVideos`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          link: youtubeLink,
          clipCount: clipCount
        }),
        credentials: 'include',
      });

      if (!response.ok) {
        if (response.status === 403) {
          const errorData = await response.json();
          if (errorData.error === "Usage limit reached for this month") {
            throw new Error("Sorry you've reached your quota for this month. Consider upgrading your plan.");
          }
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      navigate('/viral-action', {
        state: {
          processing: true,
          youtubeLink,
          sessionId: data.session_id
        },
        replace: true
      });

    } catch (error) {
      console.error('There was an error making the request', error);
      navigate('/viral-action', {
        state: {
          error: true,
          errorMessage: error instanceof Error ? error.message : 'Unknown error'
        },
        replace: true
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box
      id="hero"
      sx={{
        width: '100%',
      }}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack
          spacing={2}
          useFlexGap
          sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' } }}
        >
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              fontSize: 'clamp(3rem, 10vw, 3.5rem)',
            }}
          >
            Start&nbsp;going&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={(theme) => ({
                fontSize: 'inherit',
                color: '#1AB69D',
                fontWeight: 'bold',
                ...theme.applyStyles('dark', {
                  color: 'success.light',
                }),
              })}
            >
              Viral
            </Typography>
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              color: 'text.secondary',
              width: { sm: '100%', md: '80%' },
            }}
          >
            Enter a link to a YouTube video and our AI will generate interesting clips for you!
          </Typography>
          <Stack spacing={1} sx={{ width: '100%' }}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={1}
              useFlexGap
              sx={{ width: '100%' }}
            >
              <InputLabel htmlFor="youtube-link-hero" sx={visuallyHidden}>
                YouTube Link
              </InputLabel>
              <TextField
                id="youtube-link-hero"
                hiddenLabel
                size="medium"
                variant="outlined"
                aria-label="Enter your YouTube link"
                placeholder="Enter a YouTube link"
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    height: '56px',
                    fontSize: '1.2rem',
                  }
                }}
                slotProps={{
                  htmlInput: {
                    autoComplete: 'off',
                    'aria-label': 'Enter your YouTube link',
                  },
                }}
                value={youtubeLink}
                onChange={(e) => setYoutubeLink(e.target.value)}
              />
              <Stack spacing={1}>
                <Button
                  variant="outlined"
                  size="medium"
                  sx={{
                    backgroundColor: '#1AB69D',
                    '&:hover': {
                      backgroundColor: '#158F7D',
                    },
                    minWidth: 'fit-content',
                    height: '56px',
                    fontSize: '1.2rem',
                    color: 'white',
                  }}
                  onClick={handleGenerate}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    'Generate'
                  )}
                </Button>
                <Tooltip title="Select the maximal number of clips" arrow>
                  <ButtonGroup
                    variant="outlined"
                    aria-label="outlined button group"
                    size="small"
                  >
                    {[4, 6, 8].map((value) => (
                      <Button
                        key={value}
                        onClick={() => setClipCount(value)}
                        sx={{
                          backgroundColor: clipCount === value ? '#1AB69D' : 'transparent',
                          color: clipCount === value ? 'white' : 'inherit',
                          '&:hover': {
                            backgroundColor: clipCount === value ? '#158F7D' : 'rgba(26, 182, 157, 0.1)',
                          },
                        }}
                        disabled={
                          !user ||
                          (userPlan === 'free' && value !== 4) ||
                          (userPlan === 'basic' && value === 8)
                        }
                      >
                        {value}
                      </Button>
                    ))}
                  </ButtonGroup>
                </Tooltip>
              </Stack>
            </Stack>
          </Stack>
          {error && message && (
            <Typography color="error" sx={{ mt: 0 }}>
              {message.includes("quota") ? message : "Sorry, something went wrong. Please try again later."}
            </Typography>
          )}
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ textAlign: 'center' }}
          >
            By clicking &quot;Generate&quot; you agree to our&nbsp;
            <Link onClick={() => navigate('/terms-and-conditions')} color="primary">
              Terms & Conditions
            </Link>
            .
          </Typography>
        </Stack>
        {landing && <StyledBox id="image" />}
      </Container>
      <LoginModal />
    </Box>
  );
}

import React, { useEffect, useState } from 'react';
import { Container, Box, Typography, CircularProgress, Button, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MovieIcon from '@mui/icons-material/Movie'; // Add this import
import axios from 'axios';
import { VideoBox, VideoData } from './ViralActionPage';
import { useNavigate } from 'react-router-dom';
import AppBarToo from './components/AppBarToo';

export default function ViralVideoPage() {
    const [videoDataArray, setVideoDataArray] = useState<VideoData[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchVideoData = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN2}/api/list_videos`, {}, { withCredentials: true });
                setVideoDataArray(response.data);
            } catch (err) {
                setError(true);
                console.error('Error fetching video data:', err);
            } finally {
                setLoading(false);
            }
        };
        fetchVideoData();
    }, []);

    // Group videos by id
    const groupedVideos = videoDataArray.reduce((acc, video) => {
        if (!acc[video.id]) {
            acc[video.id] = [];
        }
        acc[video.id].push(video);
        return acc;
    }, {} as Record<string, VideoData[]>);

    const [expandedId, setExpandedId] = useState<string | null>(null);

    const handleAccordionChange = (id: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpandedId(isExpanded ? id : null);
    };

    if (loading) {
        return (
            <Container maxWidth="lg">
                <Box sx={{ pt: 8, pb: 6, p: 4, margin: 'auto', mt: 0, display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    if (error || videoDataArray.length === 0) {
        return (
            <Container maxWidth="xl">
                <AppBarToo />
                <Box sx={{ pt: 8, pb: 6, p: 4, margin: 'auto' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mb: 4,
                            p: 2,
                            borderRadius: '16px',
                            backgroundColor: 'hsl(170, 75%, 95%)',
                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <MovieIcon sx={{ fontSize: 40, mr: 2, color: 'hsl(170, 75%, 35%)' }} />
                        <Typography
                            variant="h3"
                            component="h1"
                            sx={{
                                fontWeight: 'bold',
                                background: 'linear-gradient(45deg, hsl(170, 75%, 35%), hsl(170, 75%, 55%))',
                                backgroundClip: 'text',
                                textFillColor: 'transparent',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                            }}
                        >
                            My Clips
                        </Typography>
                    </Box>
                    <Box sx={{ my: 2, borderBottom: 1, borderColor: 'grey.400' }} />
                    <Typography variant="h4" component="h4" align='center' gutterBottom>
                        {error ? "An error occurred while fetching your clips." : "Looks empty here."}
                    </Typography>
                    <Button
                        variant="outlined"
                        color="primary"
                        sx={{
                            mt: 2,
                            backgroundColor: '#1AB69D',
                            '&:hover': {
                                backgroundColor: '#158F7D',
                            },
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            width: '100%',
                            color: 'white',
                        }}
                        onClick={() => error ? navigate("/viral-video") : navigate('/viral-action')}
                    >
                        {error ? "Try again" : "Generate your first clip"}
                    </Button>
                </Box>
            </Container>
        );
    }

    return (
        <Container maxWidth="xl">
            <AppBarToo />
            <Box sx={{ pt: 8, pb: 6, p: 4, margin: 'auto', mt: 0 }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mb: 4,
                        p: 2,
                        borderRadius: '16px',
                        backgroundColor: 'hsl(170, 75%, 95%)',
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <MovieIcon sx={{ fontSize: 40, mr: 2, color: 'hsl(170, 75%, 35%)' }} />
                    <Typography
                        variant="h3"
                        component="h1"
                        sx={{
                            fontWeight: 'bold',
                            background: 'linear-gradient(45deg, hsl(170, 75%, 35%), hsl(170, 75%, 55%))',
                            backgroundClip: 'text',
                            textFillColor: 'transparent',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                        }}
                    >
                        My Clips
                    </Typography>
                </Box>
                {Object.entries(groupedVideos).map(([id, videos]) => (
                    <Accordion
                        key={id}
                        expanded={expandedId === id}
                        onChange={handleAccordionChange(id)}
                        sx={{
                            width: '100%',
                            maxWidth: '1200px',
                            margin: '0 auto 16px',
                            backgroundColor: 'hsl(170, 75%, 98.5%)',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            borderRadius: '8px',
                            '&:before': {
                                display: 'none',
                            },
                            '&.Mui-expanded': {
                                margin: '0 auto 16px',
                            },
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel-${id}-content`}
                            id={`panel-${id}-header`}
                            sx={{
                                borderRadius: '8px',
                                '&.Mui-expanded': {
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 0,
                                },
                            }}
                        >
                            <Typography variant="h6">{id}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {expandedId === id && (
                                <Box sx={{
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(4, 1fr)',
                                    gap: 2,
                                    width: '100%'
                                }}>
                                    {videos.map((video, index) => (
                                        <VideoBox key={`${id}-${index}`} videoData={video} />
                                    ))}
                                </Box>
                            )}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </Container>
    );
}